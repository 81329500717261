<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="300"
    offset-x
    :max-width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        text
        small
      >
        <v-icon
          class="pa-2"
          color="#0f0d0d80"
          small
        >
          mdi-information-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text style="overflow-y: auto; max-height: 200px;">
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="menu = false"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    name: 'QuestionInfoDialog',

    props: {
      message: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        fav: true,
        menu: false,
        hints: true,
      }
    },
  }
</script>

<style scoped>

</style>